// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.init {
    font-family: "Roboto", sans-serif !important;
  }

.farmacias-link{
    text-decoration: none;
  }
  
  .farmacias{
    font-family: "Roboto", sans-serif !important;
  }`, "",{"version":3,"sources":["webpack://./src/components/Dashboard/Pages/Dashbboard.css"],"names":[],"mappings":"AAGA;IACI,4CAA4C;EAC9C;;AAEF;IACI,qBAAqB;EACvB;;EAEA;IACE,4CAA4C;EAC9C","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');\n\n\n.init {\n    font-family: \"Roboto\", sans-serif !important;\n  }\n\n.farmacias-link{\n    text-decoration: none;\n  }\n  \n  .farmacias{\n    font-family: \"Roboto\", sans-serif !important;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
