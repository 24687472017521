// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rowCards{
    display: flex;
    padding: 0px 60px 0px 60px;
    margin-bottom: 2rem;
    margin: 0;
    width: 100%;
}
.cards{
    width: 100%;
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 728px){
    .rowCards{
        flex-direction: column;
        margin: 0;
        padding: 20px;
        gap: 1rem;
        justify-content: center;
    }
    .card{
        width: 100%;
        height: 100%;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Info.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,0BAA0B;IAC1B,mBAAmB;IACnB,SAAS;IACT,WAAW;AACf;AACA;IACI,WAAW;IACX,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI;QACI,sBAAsB;QACtB,SAAS;QACT,aAAa;QACb,SAAS;QACT,uBAAuB;IAC3B;IACA;QACI,WAAW;QACX,YAAY;IAChB;AACJ","sourcesContent":[".rowCards{\n    display: flex;\n    padding: 0px 60px 0px 60px;\n    margin-bottom: 2rem;\n    margin: 0;\n    width: 100%;\n}\n.cards{\n    width: 100%;\n    display: flex;\n    justify-content: center;\n}\n\n@media screen and (max-width: 728px){\n    .rowCards{\n        flex-direction: column;\n        margin: 0;\n        padding: 20px;\n        gap: 1rem;\n        justify-content: center;\n    }\n    .card{\n        width: 100%;\n        height: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
