// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.feed {
    background-size: cover;
    background-repeat: no-repeat;
    color: black !important;
}
.familyImage{
    width: 100%;
}

.casalIdosos{
    width: 100%;
}
#title{
    font-size: 1.5rem;
    font-weight: 300;
}

#subtitle{
    font-size: 3rem;
    font-weight: 800;
    line-height: 3.5rem;
}

.Header{
    padding: 2rem;
    align-items: center;
    text-align: left;
    min-height: 40rem;
}

#mission{
    font-size: 2rem;
    font-weight: 500;
}

#missionDescription{
    margin-top: 2rem;
    font-size: 1.2rem;
}


@media screen and (max-width: 768px) {

    #title{
        padding-top: 1rem;
    }
    #subtitle{
        font-size: 1.7rem;
        font-weight: 900;
        line-height: 30px;
        margin-bottom: 2rem;
    }
    .Header{
        text-align: center;
        flex-direction: column;
        object-fit: contain;
        background-size: 100vh !important;
        margin: 0 !important;
        padding: 0 !important;
        min-height: 30rem  !important;
        align-items: end !important;
        display: grid !important;
    }
}

    
    `, "",{"version":3,"sources":["webpack://./src/components/Feed.css"],"names":[],"mappings":";;AAEA;IACI,sBAAsB;IACtB,4BAA4B;IAC5B,uBAAuB;AAC3B;AACA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;AACf;AACA;IACI,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;AACrB;;;AAGA;;IAEI;QACI,iBAAiB;IACrB;IACA;QACI,iBAAiB;QACjB,gBAAgB;QAChB,iBAAiB;QACjB,mBAAmB;IACvB;IACA;QACI,kBAAkB;QAClB,sBAAsB;QACtB,mBAAmB;QACnB,iCAAiC;QACjC,oBAAoB;QACpB,qBAAqB;QACrB,6BAA6B;QAC7B,2BAA2B;QAC3B,wBAAwB;IAC5B;AACJ","sourcesContent":["\n\n.feed {\n    background-size: cover;\n    background-repeat: no-repeat;\n    color: black !important;\n}\n.familyImage{\n    width: 100%;\n}\n\n.casalIdosos{\n    width: 100%;\n}\n#title{\n    font-size: 1.5rem;\n    font-weight: 300;\n}\n\n#subtitle{\n    font-size: 3rem;\n    font-weight: 800;\n    line-height: 3.5rem;\n}\n\n.Header{\n    padding: 2rem;\n    align-items: center;\n    text-align: left;\n    min-height: 40rem;\n}\n\n#mission{\n    font-size: 2rem;\n    font-weight: 500;\n}\n\n#missionDescription{\n    margin-top: 2rem;\n    font-size: 1.2rem;\n}\n\n\n@media screen and (max-width: 768px) {\n\n    #title{\n        padding-top: 1rem;\n    }\n    #subtitle{\n        font-size: 1.7rem;\n        font-weight: 900;\n        line-height: 30px;\n        margin-bottom: 2rem;\n    }\n    .Header{\n        text-align: center;\n        flex-direction: column;\n        object-fit: contain;\n        background-size: 100vh !important;\n        margin: 0 !important;\n        padding: 0 !important;\n        min-height: 30rem  !important;\n        align-items: end !important;\n        display: grid !important;\n    }\n}\n\n    \n    "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
