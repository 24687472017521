// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
Body .navbar{
 font-weight: 500;
 font-size: 1rem;
   }

Body .navLink{
    color: unset;
}
.navbar{
  padding: 0;
  margin: 0 !important;
}

.logoHotsite{
  width: 15rem;
  height: 7rem;
  object-fit: contain;
  margin: 10px 0 10px 0;
}`, "",{"version":3,"sources":["webpack://./src/components/Navbar.css"],"names":[],"mappings":";AACA;CACC,gBAAgB;CAChB,eAAe;GACb;;AAEH;IACI,YAAY;AAChB;AACA;EACE,UAAU;EACV,oBAAoB;AACtB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,mBAAmB;EACnB,qBAAqB;AACvB","sourcesContent":["\nBody .navbar{\n font-weight: 500;\n font-size: 1rem;\n   }\n\nBody .navLink{\n    color: unset;\n}\n.navbar{\n  padding: 0;\n  margin: 0 !important;\n}\n\n.logoHotsite{\n  width: 15rem;\n  height: 7rem;\n  object-fit: contain;\n  margin: 10px 0 10px 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
